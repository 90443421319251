<template>
  <div class="settings-container">
    <div class="settings-wrapper rounded-lg">
      <div
        v-for="menu in menus"
        :key="menu.to"
        class="px-8 py-4 d-flex flex-row justify-space-between align-center setting-item"
        style="cursor: pointer;"
        @click="changeRoute(menu.to)">
        <span>
          {{ menu.title }}
        </span>
        <v-icon>
          mdi-chevron-right
        </v-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    menus: [
      {
        to: 'ContentsHomePage',
        title: 'หน้าเว็บไซต์'
      },
      {
        to: 'ContentsAboutUs',
        title: 'ทำไมต้อง all to trips'
      },
      {
        to: 'ContentsTerms',
        title: 'ข้อกำหนดการใช้งาน'
      },
      {
        to: 'ContentsPrivacy',
        title: 'นโยบายความเป็นส่วนตัว'
      },
      {
        to: 'ContentsCookies',
        title: 'นโยบายคุ้กกี้'
      },
      {
        to: 'ContentsHowTo',
        title: 'วิธีการจอง'
      },
      {
        to: 'ContentsHelpCenter',
        title: 'ศูนย์ช่วยเหลือ'
      },
      {
        to: 'ContentsPartner',
        title: 'พาร์ทเนอร์'
      }
    ]
  }),
  methods: {
    changeRoute (to) {
      this.$router.push({ name: to })
    }
  }
}
</script>

<style scoped>
.settings-container {
  width: 100%;
  padding: 0.5rem 0;
}
.settings-wrapper {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.setting-item {
  border-bottom: 1px solid #E0E0E0;
  transition: all;
  transition-duration: 150ms;
}
.setting-item:first-child {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.setting-item:last-child {
  border-bottom: none;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.setting-item:hover {
  background-color: #026EAA;
  color: white;
}
</style>
